<template>
    <div class="container w-full lg:w-5/6 xl:w-3/4 2xl:w-1/2 mx-auto flex flex-col py-6 px-3">
        <h2 class="text-3xl text-primary md:text-4xl font-medium mb-2">Mijn wachtlijst</h2>
        <template v-if="showContent">
            <Event v-for="(event) of localRequests" v-bind="event" v-bind:key="event.eventid" v-bind:nbroftickets="nbrOfTickets(event)">
                <div class="relative pr-4">
                    <button 
                        @click="initCancel(event)" 
                        class="py-2 px-4 capitalize tracking-wide bg-primary hover:bg-primary-light text-white font-medium rounded
                            transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                        Annuleren
                    </button>
                </div>
            </Event>
            <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler">
                <div slot="spinner">
                    <font-awesome-icon icon="spinner" spin="spin" size="3x" class="text-primary block m-auto"/>
                </div>
                <div class="text-left" slot="no-more"></div>
                <div class="text-left" slot="no-results">Je hebt geen wachtlijst aanvragen voor toekomstige evenementen.</div>
            </infinite-loading>
        </template>
        <div v-if="error">
            <p>
                Het is niet gelukt de gegevens op te halen. 
            </p>
            <p>
                Probeer het later nog een keer of neem contact met ons op via 
                <a class="underline" :href="`mailto:${config.email}`" target="_blank">{{ config.email }}</a> 
                <span v-if="config.phone">
                of {{ config.phone }}
                </span>.
            </p>
        </div>  

        <Modal 
            button="Sluiten" 
            title="Annuleren" 
            icon="spinner"
            ref="cancelLoadingModal">
        </Modal>

        <ConfirmationModal 
            @execute="executeCancel" 
            @cancel="clearCancel" 
            title="Annuleren"
            confirmation="Ik weet zeker dat ik deze aanvraag wil annuleren."
            ref="cancelConfirmModal"
            >
            <p class="my-1 sm:my-4 text-md sm:text-xl leading-relaxed">
                Annuleer hier je aanvraag voor <b>{{ cancelState.name }}</b> 
                op {{ cancelState.startts | friendlyDate }}.
            </p>
        </ConfirmationModal>

        <Modal
            @closed="refreshWaitinglist" 
            title="Het is gelukt" 
            icon="check-circle"
            ref="cancelFinishedModal"
        >
            <p class="text-lg">
                Je aanvraag is geannuleerd.
            </p>
        </Modal>

        <Modal
            @closed="refreshWaitinglist"
            title="Niet gelukt"
            ref="errorModal"
            icon="exclamation-circle"
        >
            <p class="text-lg">
                Het is niet gelukt om deze aanvraag te annuleren.
            </p>
            <p class="text-lg">
                Neem voor vragen contact op met <a :href="`mailto:${ config.email }`">{{ config.email }}</a>.
            </p>
        </Modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading';
import { deleteRequestExecute } from '../services/WaitinglistService'
import Event from '../components/Event.vue'
import Modal from '../components/Modal.vue'
import ConfirmationModal from '../components/ConfirmationModal.vue'
const moment = require("moment")
const R = require("ramda")
moment.locale("nl")

const STEPSIZE = 5

export default {
  name: 'Ticketmatic',
  title: 'Mijn Wachtlijst',
  components: {
      Event,
      InfiniteLoading,
      Modal,
      ConfirmationModal,
  },
  data() {
    return {
        cancelState: {
            eventname: "",
        },
        localRequests: [],
        counter: 0,
        error: false,
    }
  },
  computed: {
    ...mapGetters(["config", "waitinglist"]),
    showContent: function() {
        return (!this.error)
    }
  },
  methods: {
    nbrOfTickets(event) {
        const allWithTicketInfo = R.pipe(R.pluck('itemsstatus'), R.all(x => x === 29102 || x === 29103))(event.requests)
        if (!allWithTicketInfo) {
            return 0
        }
        return R.pipe(R.pluck('nbroftickets'), R.sum)(event.requests)
    },
    initWaitinglist: function () {
        this.counter = 0;
        this.localRequests = [];
    },
    refreshWaitinglist: async function () {
        this.clearCancel();
        await this.$store.dispatch("setWaitinglist");
    },
    infiniteHandler: function($state) {
        setTimeout(() => {
            if (this.waitinglist.events === null) {
                return
            }
            const items = this.waitinglist.events.slice(this.counter, this.counter + STEPSIZE);
            this.localRequests.push(...items);
            this.counter += STEPSIZE;
            if (items.length > 0) {
                $state.loaded();
            }
            if (this.localRequests.length === this.waitinglist.events.length) {
                $state.complete();
            }
        }, 500);
    },
    clearCancel: function () {
        this.cancelState = {
            eventname: "",
        }
        this.cancelResult = {
        }
    },    
    initCancel: async function (event) {
        this.cancelState = event;
        this.$refs.cancelConfirmModal.toggle();
    },
    executeCancel: async function () {
        try {
            this.$refs.cancelLoadingModal.toggle();
            const result = await deleteRequestExecute(this.cancelState.id);
            this.cancelResult = result;
            this.$refs.cancelLoadingModal.toggle();
            this.$refs.cancelFinishedModal.toggle();
        } 
        catch(err) {
            console.log(err)
            this.$refs.cancelLoadingModal.toggle();
            this.$refs.errorModal.toggle();
        }
    },    
  },
  filters: {
    friendlyDate: function (date) {
        return moment(date).format('D MMMM YYYY')
    },
  },
  watch: {
      waitinglist: {
          handler(val, oldVal) {
              if (!this.waitinglist) {
                  return;
              }
              if (!this.waitinglist.events) {
                  return;
              }
              if (R.equals(val, oldVal)) {
                  return;
              }
              this.initWaitinglist();
              if (this.$refs.InfiniteLoading) {
                  this.$refs.InfiniteLoading.stateChanger.reset(); 
              }          
          },
          deep: true,
      },
  },  
  async mounted () {
    this.$mixpanel.track('pageview', {
        distinct_id: this.$auth.user,
        account: this.config.shortname,
        page: "Waitinglist",
    })

    if (this.waitinglist && this.waitinglist.events) {
        return; // do not refresh every mount
    }    

    try {
        await this.$store.dispatch('setWaitinglist')
    } catch (ex) {
        this.error = true;
        this.$store.dispatch('resetWaitinglist')
    }
  },
}
</script>